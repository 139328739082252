// REACT
import { BrowserRouter } from "react-router-dom";

// HOOKS
import useSettings from "./hooks/useSettings";

// CONFIGS
import Routes from "./routes";
import { scpTheme } from "./theme/Settings";

// MUI
import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";

const App = () => {
  const { settings } = useSettings();

  // Load Theme
  const appTheme = scpTheme({
    theme: settings.theme,
    responsiveFontSizes: settings.responsiveFontSizes,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
