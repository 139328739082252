import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const StyledLoadingBar = styled(LinearProgress)(({ theme }) => ({
  background: "linear-gradient(to right,rgba(29, 255, 180, 0.3),rgba(0, 148, 246, 0.3))",

  '& 	.MuiLinearProgress-bar': {
    background: "linear-gradient(to right,#1dffb4,#0094f6)"
  }
}))

const LoadingBar = () => {
  return (
    <StyledLoadingBar />
  )
}

export default LoadingBar;
