import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import LoadingBar from "./components/layout/LoadingBar";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingBar />}>
      <Component {...props} />
    </Suspense>
  );

// PAGES
const IndexPage = Loadable(lazy(() => import("./pages/Index")));
//TOKEN
const Contract = Loadable(lazy(() => import("./pages/token/Contract")));
const Address = Loadable(lazy(() => import("./pages/token/Address")));
const Block = Loadable(lazy(() => import("./pages/token/Block")));
const AllBlocks = Loadable(lazy(() => import("./pages/token/AllBlocks")));
const Transaction = Loadable(lazy(() => import("./pages/token/Transaction")));
const AllTransactions = Loadable(lazy(() => import("./pages/token/AllTransactions")));
// NFT
const NFTExploreCollections = Loadable(lazy(() => import("./pages/nft/NFTExploreCollections")));
const NFTCollection = Loadable(lazy(() => import("./pages/nft/NFTCollection")));
const NFTDetails = Loadable(lazy(() => import("./pages/nft/NFTDetails")));
// DOWNLOAD
const Download = Loadable(lazy(() => import("./pages/Download")));

// MONITOR MASTERNODES
const NodeTracker = Loadable(lazy(() => import("./pages/NodeTracker")));

// EARNDROPS
const Login = Loadable(lazy(() => import("./pages/earndrops/Login")));
const LoginCallback = Loadable(lazy(() => import("./pages/earndrops/LoginCallback")));

//MISC
const Error404 = Loadable(lazy(() => import("./pages/Error404")));

const routes = () => {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
      {/* Tokens */}
      <Route path="/contract/:id" element={<Contract />} />
      <Route path="/address/:id" element={<Address />} />
      <Route path="/block/:id" element={<Block />} />
      <Route path="/blocks" element={<AllBlocks />} />
      <Route path="/transactions" element={<AllTransactions />} />
      <Route path="/transaction/:id" element={<Transaction />} />
      {/* Nfts */}
      <Route path="/nft/" element={<NFTExploreCollections />} />
      <Route path="/nft/collection/:id" element={<NFTCollection />} />
      <Route path="/nft/:id" element={<NFTDetails />} />
      {/* Download */}
      <Route path="/download" element={<Download />} />
      {/* Masternode Sentinel */}
      <Route path="/node-tracker" element={<NodeTracker />} />

      {/* EarnDrops */}
      <Route path="/login" element={<Login />} />
      <Route path="/login/callback" element={<LoginCallback />} />

      {/* Misc */}
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default routes;
