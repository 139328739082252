import { createTheme } from "@mui/material";
import merge from "lodash.merge";

const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};

const FONT = "Inter, sans-serif";

const baseOptions = {
  direction: "ltr",
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1440,
      xxl: 1920,
    },
  },
};

const colorPalette = (mode) => {
  const getColor = (darkColor, lightColor) => {
    return mode === "dark" ? darkColor : lightColor;
  };

  // Available colors
  return {
    palette: {
      mode,
      // Typography
      text: {
        primary: getColor("#F6F7FB", "#1c1e20"), // Title & Highlights
        secondary: getColor("#F6F7FB", "#4d4d4d"), // Text, Paragraphs, Units, Icons
        dark: getColor("#7E8EA4", "#7E8EA4"),
        darker: getColor("#3A3D54", "#3A3D54"),
        lightGrey: getColor("#878787", "#878787"),
        white: getColor("#F2F2F2", "#F2F2F2"),
        whiter: getColor("#FFFFFF", "#FFFFFF"),
      },
      // Brand Specific
      primary: {
        main: getColor("#0fcad5", "#0fcad5"), // Call to Action, Interactive Element
      },
      secondary: {
        main: getColor("#0094f6", "#0094f6"), // Highlight, Non-Interactive
      },
      gradients: {
        brand: "linear-gradient(to right, #1dffb4, #0094f6)",
      },
      //Light
      whiteColor: {
        main: getColor("FFFFFF", "#000000"),
      },
      // Status & Alerts
      success: {
        main: getColor("#00B050", "#00B050"),
      },
      info: {
        main: getColor("#2f8af5", "#2f8af5"),
      },
      warning: {
        main: getColor("#ed6c02", "#ed6c02"),
      },
      error: {
        main: getColor("#e3402a", "#e3402a"),
      },
      // Layout
      background: {
        default: getColor("#1b2030", "#f2f2f2"), // Body
        paper: getColor("#FFFFFF", "#FFFFFF"), 
        paper2: getColor("#F2F2F2", "#f2f2f2"), 
        paper3: getColor("#1E1E1E", "#1E1E1E"), 
        paper4: getColor("#101010", "#101010"), 
        paper5: getColor("#7E8EA430", "#7E8EA430"), 
      },
      divider: getColor("#ebebef14", "#cccccc"),
    },
  };
};

const typographyOptions = (color) => {
  return {
    typography: {
      // Main
      fontFamily: FONT,
      fontSize: 14,
      htmlFontSize: 16,
      // Page & Section Title
      pageTitle: {
        fontFamily: FONT,
        fontSize: "1.5rem",
        fontWeight: 500,
        color: color.palette.text.darker,
      },
      title: {
        fontFamily: FONT,
        fontSize: "1rem",
        fontWeight: 500,
        color: color.palette.text.darker,
      },
      // Default Text
      body1: {
        fontFamily: FONT,
        fontSize: "0.875rem",
        fontWeight: 400,
        color: color.palette.text.secondary,
      },
      display1: {
        fontFamily: FONT,
        fontSize: "0.875rem",
        fontWeight: 400,
        color: color.palette.text.darker,
      },
      // Helper Text
      caption: {
        fontFamily: FONT,
        color: color.palette.text.secondary,
      },
      // Page Highlights (rarely used, mainly to highlight important numbers (TVL, total balance, etc))
      highlight: {
        fontFamily: FONT,
        fontSize: "1.75rem",
        fontWeight: 500,
        color: color.palette.text.primary,
        lineHeight: 1.2,
      },
      highlightMuted: {
        fontFamily: FONT,
        fontSize: "1.75rem",
        fontWeight: 500,
        color: color.palette.text.secondary,
        lineHeight: 1.2,
      },
      // Disable unused MUI defaults
      h1: undefined,
      h2: undefined,
      h3: undefined,
      h4: undefined,
      h5: undefined,
      h6: undefined,
      subtitle1: undefined,
      subtitle2: undefined,
      body2: undefined,
      overline: undefined,
    },
  };
};

const customComponents = (color) => {
  return {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            background: "linear-gradient(90deg, rgba(33,34,38,1) 0%, rgba(44,46,51,1) 50%, rgba(33,34,38,1) 100%)",
            height: "100vh",
            fontFamily: FONT,
            // "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            //   width: 8,
            //   height: 8,
            // },
            // "&::-webkit-scrollbar-thumb": {
            //   borderRadius: 4,
            //   backgroundColor: color.palette.background.paper,
            //   outline: "none",
            // },
            // "& .MuiTableContainer-root::-webkit-scrollbar-thumb": {
            //   borderRadius: 4,
            //   backgroundColor: color.palette.background.default,
            //   outline: "none",
            // },
            // "& .MuiPaper-root fieldset": {
            //   borderColor: color.palette.background.default,
            // },
          },
        },
      },
    },
  };
};

const scpTheme = (config) => {
  const palette = colorPalette(config.theme);
  const merged = merge({}, baseOptions, palette, typographyOptions(palette), customComponents(palette));
  let theme = createTheme(merged);

  return theme;
};

export { THEMES, scpTheme };
